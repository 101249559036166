import {
    Button,
    Dropdown,
    Field,
    FluentProvider,
    Input,
    Switch,
    makeStyles,
    shorthands,
    tokens,
    Option,
    TableColumnDefinition,
    createTableColumn,
    TableCellLayout,
    DataGrid,
    DataGridHeader,
    DataGridRow,
    DataGridHeaderCell,
    DataGridBody,
    DataGridCell,
    TableRowId,
    Text,
    Divider,
    Tooltip,
} from '@fluentui/react-components';
import React, { useRef, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useGPT } from '../../libs/hooks/useGPT';
import { AssistantTools, IFile, IGPT } from '../../libs/models/GPT';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { FormTextField } from '../custom-gpt/FormTextField';
import { NestedItemsFieldArray } from '../custom-gpt/NestedItemsFieldArray';
import { FileTableItem } from '../custom-gpt/RegisterGPTWindow';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { setUserGPTs } from '../../redux/features/gpt/gptSlice';
import { useParams } from 'react-router-dom';
import { customColors, customFonts, ScrollBarStyles } from '../../styles';
import { AddCircleRegular, ArrowUploadRegular, DeleteFilled, Info24Regular } from '@fluentui/react-icons';
import { useUserRole } from '../../libs/hooks/useUserRole';
import DOMPurify from 'dompurify';

const useClasses = makeStyles({
    title: {
        marginBottom: '20px',
        fontSize: '3em',
        fontWeight: '500',
        display: 'flex',
        justifyContent: 'center',
        color: customColors.summarizeButtonDarker,
        fontFamily: customFonts.TitleFont,
        lineHeight: '80%',
    },
    subtitle: {
        fontSize: '2em',
        marginBottom: '30px',
        marginTop: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: '300',
        color: customColors.headerLightGray,
        fontFamily: customFonts.TitleFont,
    },
    header: {
        ...shorthands.padding(tokens.spacingVerticalM, tokens.spacingHorizontalL),
        backgroundColor: tokens.colorNeutralBackground1,
        textAlign: 'center',
        height: '150px',
        width: '90% !important',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center',
    },
    useStackClassName: {
        display: 'flex',
        width: '70% !important',
        flexDirection: 'column',
        alignContent: 'center',
        rowGap: '2.5em',
        ...shorthands.padding('6em', '10em'),
        ...shorthands.overflow('hidden', 'scroll'),
    },
    fieldName: {
        marginBottom: '5px',
        fontSize: '1.5em',
        fontWeight: '500',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        color: customColors.summarizeButtonDarker,
        fontFamily: customFonts.TitleFont,
    },
    switchText: {
        color: customColors.headerLightGray,
        fontSize: '1.1em',
        fontWeight: 500,
    },
    functional: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.margin('0', '0', tokens.spacingVerticalS, '0'),
        justifyContent: 'left',
    },
    toastMessage: {
        fontSize: '18px',
        color: 'black',
        fontFamily: customFonts.TitleFont,
        //backgroundColor: '#F7F7F8',
        border: '1px solid black',
        marginTop: '33px',
    },
    divider: {
        color: customColors.protivitiDarkBlue,
        fontSize: '30px',
        fontFamily: customFonts.TitleFont,
    },
    iconStyle: {
        marginLeft: '8px',
        transform: 'scale(0.90)',
        cursor: 'pointer',
        color: customColors.summarizeButtonDarker,
    },
    assistantToolTipDiv: {
        padding: '1em',
        margin: '1em',
        backgroundColor: '#ffffff',
        borderRadius: '8px',
        border: '1px solid #ddd',
        overflowY: 'auto',
        maxHeight: '75vh',
        ...ScrollBarStyles,
    },
    assistantToolTip: {
        minWidth: '30vw',
    },
});

const toastAppearance = 3000;

export const EditGPTWindow: React.FC = () => {
    const classes = useClasses();
    const gpt = useGPT();
    let editGPT = useSelector((state: RootState) => state.app.selectedGPT);

    if (!editGPT) {
        const id = useParams();
        const { userGPTs } = useSelector((state: RootState) => state.gpts);
        editGPT = userGPTs.find((gpt) => gpt.id === id.gptId) ?? null;
    }

    const [isSwitchEnabled, setIsSwitchEnabled] = React.useState<boolean>(editGPT?.gptEndpoint !== '' ? true : false);
    const [isPrivate, setIsPrivate] = React.useState<boolean>(editGPT?.isPrivate ? true : false);
    const [isModified, setIsModified] = React.useState<boolean>(editGPT?.userModifyPersona ? true : false);
    const [isDocumentUploadEnabled, setIsDocumentUploadEnabled] = React.useState<boolean>(
        editGPT?.isDocumentUploadEnabled ? true : false,
    );
    const documentFileRef = React.useRef<HTMLInputElement | null>(null);
    const selectedFileImports = React.useRef(new Set<string>());
    const [selectedFileDeletes, setSelectedFileDeletes] = React.useState<TableRowId[]>([]);
    const [assistantFiles, setAssistantFiles] = React.useState<File[]>([]);
    const [existingFiles, setExistingFiles] = React.useState<IFile[]>(
        editGPT?.assistantFiles ? editGPT.assistantFiles : [],
    );
    const [deletedExistingFiles, setDeletedExistingFiles] = React.useState<IFile[]>([]);
    const [disableButton, setDisableButton] = React.useState<boolean>(false);
    const [isFileSeachConfigEnabled, setIsFileSearchConfigEnabled] = React.useState(
        editGPT?.fileSearchConfig?.isEnabled ? true : false,
    );
    const [maxChunkSizeTokens, setMaxChunkSizeTokens] = useState(
        editGPT?.fileSearchConfig?.maxChunkSizeTokens == null || editGPT?.fileSearchConfig?.maxChunkSizeTokens === 0
            ? 800
            : editGPT?.fileSearchConfig?.maxChunkSizeTokens,
    );
    const [maxChunkOverlapTokens, setMaxChunkOverlapTokens] = useState(
        editGPT?.fileSearchConfig?.maxChunkSizeTokens == null || editGPT?.fileSearchConfig?.maxChunkSizeTokens === 0
            ? 400
            : editGPT?.fileSearchConfig?.maxChunkSizeTokens,
    );
    const [maxNumResults, setMaxNumResults] = useState(
        editGPT?.fileSearchConfig?.maxNumResults == null || editGPT?.fileSearchConfig?.maxNumResults === 0
            ? 20
            : editGPT?.fileSearchConfig?.maxNumResults,
    );
    const [userRole, setUserRole] = useState<string | null>(null);
    // const userManagementService = new UserManagementService(process.env.REACT_APP_BACKEND_URI as string);
    //const { instance, inProgress } = useMsal();
    const { activeUserInfo } = useAppSelector((state: RootState) => state.app);
    const [totalFilesAfterUpload, setTotalFilesAfterUpload] = useState(editGPT?.assistantFiles?.length ?? 0);
    const [capabilities, setCapabilities] = useState<string[] | null | undefined>(editGPT?.gptCapabilities);
    const [owners, setOwners] = useState<string[]>(
        editGPT?.gptOwnerInfo?.map(owner => owner.userPrincipalName) ?? []
    );

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    // const usersState = useAppSelector((state: RootState) => state.users.users);
    const { fetchUserRole } = useUserRole();
    const [ownershipMode] = useState<'owners' | 'support'>(() => {
        if (editGPT?.gptSupportAddress && editGPT.gptSupportAddress.trim() !== '') {
            return 'support';
        } else if (editGPT?.gptOwnerInfo && editGPT.gptOwnerInfo.length > 0) {
            return 'owners';
        }
        return 'support';
    });

    useEffect(() => {
        if (editGPT?.gptOwnerInfo && editGPT.gptOwnerInfo.length > 0) {
            setOwners(editGPT.gptOwnerInfo.map((owner) => owner.userPrincipalName)); // Pre-fill owners
        }
    }, [editGPT?.gptOwnerInfo]);



    useEffect(() => {
        if (editGPT?.assistantFiles)
            editGPT?.assistantFiles.forEach((file) => selectedFileImports.current.add(file.fileName));
    }, []);

    useEffect(() => {
        const fetchRoleForUser = async () => {
            if (!activeUserInfo?.email) return; // Ensure email exists

            try {

                //console.log('Fetching role for email:', activeUserInfo.email);
                const role = await fetchUserRole(activeUserInfo.email); // Use centralized logic
                if (role) {
                    console.log('Role fetched successfully:', role);
                    setUserRole(role); // Update local state
                }
            } catch (error) {
                console.error('Error fetching role in EditGPTWindow:', error);
            }
        };

        fetchRoleForUser();
    }, [activeUserInfo?.email, fetchUserRole]);

    const {
        handleSubmit,
        control,
        watch,
        setValue,
        formState: { errors, isSubmitting },
    } = useForm<IGPT>({
        defaultValues: {
            promptLibrary:
                editGPT?.promptLibrary && editGPT.promptLibrary.length > 0
                    ? editGPT.promptLibrary
                    : [
                        {
                            smallBlock: [{ name: '', description: '' }],
                        },
                    ],
            faQs: editGPT?.faQs && editGPT.faQs.length > 0 ? editGPT.faQs : [{ question: '', answer: '' }],
            relatedLinks:
                editGPT?.relatedLinks && editGPT.relatedLinks.length > 0
                    ? editGPT.relatedLinks
                    : [{ title: '', link: '' }],
            ownershipMode:
                    editGPT?.gptOwnerInfo && editGPT.gptOwnerInfo.length > 0
                      ? 'owners'
                      : editGPT?.gptSupportAddress && editGPT.gptSupportAddress.trim() !== ''
                      ? 'support'
                      : 'support',
        },
    });

    const {
        fields: promptLibraryFields,
        append: appendPromptLibrary,
        remove: removePromptLibrary,
    } = useFieldArray({
        control,
        name: 'promptLibrary',
    });

    const { fields: faqFields } = useFieldArray({
        control,
        name: 'faQs',
    });

    const { fields: relatedLinksFields } = useFieldArray({
        control,
        name: 'relatedLinks',
    });

    const addCapability = () => {
        if (capabilities) {
            const newCapabilities = [...capabilities, ''];
            setCapabilities(newCapabilities);
            setValue('gptCapabilities', newCapabilities);
        } else {
            setCapabilities(['']);
            setValue('gptCapabilities', ['']);
        }
    };
    const removeCapability = (index: number) => {
        const newCapabilities = capabilities?.filter((_, i) => i !== index);
        setCapabilities(newCapabilities);
        setValue('gptCapabilities', newCapabilities);
    };

    const handleCapabilityInputChange = (value: string, index: number) => {
        const newCapabilities = capabilities?.map((capability, i) => (i === index ? value : capability));
        setCapabilities(newCapabilities);
        setValue('gptCapabilities', newCapabilities);
    };

    const gptType = watch('gptType', editGPT?.gptType);
    const assistantTools = watch('tools', editGPT?.tools);

    const codeInterpreterFiles = [
        '.c',
        '.cs',
        '.cpp',
        '.csv',
        '.doc',
        '.docx',
        '.html',
        '.java',
        '.json',
        '.md',
        '.pdf',
        '.php',
        '.pptx',
        '.py',
        '.rb',
        '.tex',
        '.txt',
        '.css',
        '.js',
        '.sh',
        '.ts',
        '.jpeg',
        '.jpg',
        '.gif',
        '.pkl',
        '.png',
        '.tar',
        '.xlsx',
        '.xml',
        '.zip',
    ];

    const isCodeInterpreter = assistantTools?.includes(AssistantTools['Code Interpreter']);
    const isFileSearch = assistantTools?.includes(AssistantTools['File Search']);
    const fileSearchFiles = [
        '.c',
        '.cpp',
        '.cs',
        '.css',
        '.doc',
        '.docx',
        '.go',
        '.html',
        '.java',
        '.js',
        '.json',
        '.md',
        '.pdf',
        '.php',
        '.pptx',
        '.py',
        '.rb',
        '.sh',
        '.tex',
        '.ts',
        '.txt',
    ];

    // Calculate accepted file types based on selections
    const getAcceptedFiles = () => {
        let acceptedFiles: string[] = [];

        if (isCodeInterpreter) {
            acceptedFiles = [...acceptedFiles, ...codeInterpreterFiles];
        }

        if (isFileSearch) {
            acceptedFiles = [...acceptedFiles, ...fileSearchFiles];
        }

        // Remove duplicates from the array
        return [...new Set(acceptedFiles)].join(',');
    };

    const fileTableColumns: TableColumnDefinition<FileTableItem>[] = [
        createTableColumn<FileTableItem>({
            columnId: 'file',
            renderHeaderCell: () => {
                return 'File';
            },
            renderCell: (item) => {
                return <TableCellLayout>{item.file}</TableCellLayout>;
            },
        }),
        createTableColumn<FileTableItem>({
            columnId: 'size',
            renderHeaderCell: () => {
                return 'Size';
            },
            renderCell: (item) => {
                return <TableCellLayout>{item.size}</TableCellLayout>;
            },
        }),
    ];

    const newlyImportedFiles = assistantFiles
        ? assistantFiles.map((item) => ({
            file: item.name,
            size: `${(item.size / (1024 * 1024)).toFixed(2)} MB`,
        }))
        : [];

    const existingImportedFiles = existingFiles.map((item) => ({
        file: item.fileName,
        size: `${(item.bytes / (1024 * 1024)).toFixed(2)} MB`,
        fileId: item.id,
    }));

    const displayedFiles = [...existingImportedFiles, ...newlyImportedFiles];

    const models = JSON.parse(process.env.REACT_APP_MODELS || '[]');

    const modelOptions = models.map((model: string) => ({
        key: model,
        header: model.toUpperCase(),
    }));

    const fileInputRef = useRef<HTMLInputElement>(null);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [selectedFileName, setSelectedFileName] = useState<string | undefined>(editGPT?.gptIconFileName);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file && file.type.startsWith('image/')) {
            setSelectedFile(file); // Store the entire File object
            setSelectedFileName(file.name);
        } else {
            alert('Please select a valid image file.');
        }
    };
    const formatURL = (url: string) => {
        try {
            const trimmedUrl = url.trim();
            if (trimmedUrl === "") return trimmedUrl;
            const urlObj = new URL(trimmedUrl)
            if (!['http:', 'https:'].includes(urlObj.protocol)) {
                throw new Error('Invalid protocol. Please ensure your link starts with http:// or https://')
            }
            return urlObj.toString()
        } catch (e) {
            throw new Error('Invalid protocol. Please ensure your link starts with http:// or https://');
        }
    };
    const onSubmit: SubmitHandler<IGPT> = async (data: any) => {

        if (selectedFileName == null) {
            const errorMessage = 'Card Image Upload is required.';
            toast.error(errorMessage, {
                className: classes.toastMessage,
            });
            return;
        }
        let formattedLinks: { title: string; link: string }[] = [];
        for (let item of data.relatedLinks) {
            try {
                formattedLinks.push({
                    title: DOMPurify.sanitize(item.title),
                    link: formatURL(item.link),
                });
            } catch (e) {
                const errorMessage = `Invalid URL format: ${item.link}. Please ensure your link starts with http:// or https://`;
                toast.error(errorMessage, {
                    className: classes.toastMessage,
                });
                return;
            }
        }
        var filesToDelete = deletedExistingFiles;
        if (
            !data.tools?.includes(AssistantTools['Code Interpreter']) &&
            !data.tools?.includes(AssistantTools['File Search'])
        ) {
            filesToDelete = existingFiles;
        }

        const fileSearchConfig = data.fileSearchConfig
            ? {
                isEnabled: true,
                maxChunkSizeTokens: data.fileSearchConfig.maxChunkSizeTokens,
                chunkOverlapTokens: data.fileSearchConfig.chunkOverlapTokens,
                maxNumResults: data.fileSearchConfig.maxNumResults,
            }
            : editGPT?.fileSearchConfig;

        let gptInstance: IGPT = {
            id: editGPT?.id !== undefined ? editGPT?.id : '',
            partitionID: editGPT?.partitionID !== undefined ? editGPT?.partitionID : '',
            gptType: data.gptType,
            model: data.model !== undefined ? data.model : editGPT?.model,
            tools: data.tools ?? editGPT?.tools,
            fileSearchConfig: fileSearchConfig,
            assistantFiles: editGPT?.assistantFiles,
            vectorStoreIds: editGPT?.vectorStoreIds,
            gptEndpoint: isSwitchEnabled ? data.gptEndpoint : '',
            name: data.name ?? editGPT?.name,
            description: data.description ?? editGPT?.description,
            isPrivate: isPrivate,
            isActive: editGPT?.isActive ? true : false,
            isRegistered: editGPT?.isRegistered ? true : false,
            categoryTagStrings: data.tag.split(',') ?? editGPT?.categoryTagStrings,
            persona: data.persona ?? editGPT?.persona,
            userModifyPersona: isModified,
            cardImageURL: data.cardImageURL ?? editGPT?.cardImageURL,
            gptIconFileName: data.gptIconFileName ?? editGPT?.gptIconFileName,
            cardShortDescription: data.cardShortDescription ?? editGPT?.cardShortDescription,
            tag: data.tag,
            ratings: null,
            promptLibrary: data.promptLibrary ?? editGPT?.promptLibrary,
            totalRating: editGPT?.totalRating,
            isDocumentUploadEnabled: isDocumentUploadEnabled,
            faQs: data.faQs ?? editGPT?.faQs,
            relatedLinks: formattedLinks ?? editGPT?.relatedLinks,
            gptKnowledgeBase: data.gptKnowledgeBase ?? editGPT?.gptKnowledgeBase,
            gptCapabilities: data.gptCapabilities ?? editGPT?.gptCapabilities,
            gptOwnerInfo: owners.map((email) => ({
                key: '',
                givenName: '',
                surName: '',
                userPrincipalName: email.trim(),
                mail: '',
                photoUrl: '',
            })),
            gptOwnerBlurb: data.gptOwnerBlurb ?? editGPT?.gptOwnerBlurb,
            gptSupportAddress: data.gptSupportAddress ?? editGPT?.gptSupportAddress
        };
        try {
            const result = await gpt.updateGPT(gptInstance, selectedFile as File, assistantFiles, filesToDelete);
            if (result) {
                setDisableButton(true);
                toast.success('GPT updated successfully', {
                    className: classes.toastMessage,
                });

                dispatch(setUserGPTs({ gpts: await gpt.getGPTs() }));

                setTimeout(() => {
                    navigate('/manage-gpts');
                }, toastAppearance);
            }
        } catch {
            const errorMessage = `Unable to update GPT. Please refresh the page or contact support.`;
            toast.error(errorMessage, {
                className: classes.toastMessage,
            });
        }
    };

    const handleCancel = () => {
        navigate('/manage-gpts');
    };

    const sanitizeAndProcessFile = async (
        file: File,
        currentTotalFiles: number,
        maxFileCount: number,
        maxFileSizeBytes: number,
        maxFileSizeMB: number,
        classes: any,
    ): Promise<File | null> => {
        const readFileContent = (file: File): Promise<string> => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result as string);
                reader.onerror = reject;
                reader.readAsText(file);
            });
        };

        const removeAsciiCharacters = (content: string): string => {
            let i = 0;
            while (i < content.length && content.charCodeAt(i) < 65) {
                i++;
            }
            return content.slice(i);
        };

        const sanitizeContent = (content: string): string => {
            content = removeAsciiCharacters(content);
            return content.replace(/[\x00-\x08\x0B\x0C\x0E-\x1F\x7F]/g, '');
        };

        const processFile = async (file: File): Promise<File | null> => {
            if (currentTotalFiles < maxFileCount) {
                if (file.size <= maxFileSizeBytes) {
                    const content = await readFileContent(file);
                    const sanitizedContent = sanitizeContent(content);
                    return new File([sanitizedContent], file.name, {
                        type: file.type,
                    });
                } else {
                    toast.error(
                        `File ${file.name} with size ${file.size} exceeds the ${maxFileSizeMB} MB size limit.`,
                        {
                            className: classes.toastMessage,
                        },
                    );
                }
            }
            return null;
        };

        return processFile(file);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={classes.useStackClassName}>
            <div
                className={classes.header}
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignContent: 'center',
                    justifyContent: 'center',
                    marginBottom: '-2em',
                }}
            >
                <Text as="h1" className={classes.title}>
                    Edit GPT: {editGPT?.name}
                </Text>
                <Text as="p" className={classes.subtitle}>
                    Use this page to edit existing specialized GPTs
                </Text>
            </div>
            <Field
                label={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Text className={classes.fieldName}>GPT Endpoint</Text>
                        <div style={{ maxWidth: '300px', whiteSpace: 'nowrap', textAlign: 'left' }}>
                            <Tooltip
                                content="Provide the GPT Endpoint or leave blank if registering a new GPT. This is essential for pre-existing models."
                                relationship="label"
                                positioning="after"
                            >
                                <Info24Regular
                                    className={classes.iconStyle}
                                    onClick={(e) => {
                                        e.preventDefault();
                                    }}
                                />
                            </Tooltip>
                        </div>
                    </div>
                }
                validationState={errors.gptEndpoint ? 'error' : 'none'}
                validationMessage={errors.gptEndpoint ? errors.gptEndpoint.message : null}
                size="large"
                style={{ display: 'flex', justifyContent: 'space-between' }}
            >
                <Switch
                    checked={isSwitchEnabled}
                    onChange={() => setIsSwitchEnabled(!isSwitchEnabled)}
                    label={
                        <Text className={classes.switchText}>{isSwitchEnabled ? 'Pre Existing GPT' : 'New GPT'}</Text>
                    }
                    disabled={true}
                />
            </Field>

            <Controller
                name="gptEndpoint"
                control={control}
                defaultValue={editGPT?.gptEndpoint}
                rules={{
                    required: isSwitchEnabled ? 'GPT Endpoint is required.' : undefined,
                }}
                render={({ field }) => (
                    <Input
                        {...field}
                        disabled={true}
                        placeholder={editGPT?.gptEndpoint}
                        style={{
                            marginTop: '-2em',
                            borderRadius: 0,
                            border: `2px solid ${customColors.headerLightGray}`,
                        }}
                        appearance="outline"
                    />
                )}
            />

            <Field
                label={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Text className={classes.fieldName}>GPT Type</Text>
                        <Tooltip
                            content="Toggle this option if this GPT is an Assistant-type GPT. Assistant GPTs have specific functionalities such as File Search and Code Interpeter."
                            relationship="label"
                            positioning="after"
                        >
                            <Info24Regular
                                className={classes.iconStyle}
                                onClick={(e) => {
                                    e.preventDefault();
                                }}
                            />
                        </Tooltip>
                    </div>
                }
                validationState={errors.gptType ? 'error' : 'none'}
                validationMessage={errors.gptType ? errors.gptType.message : null}
            >
                <Controller
                    name="gptType"
                    control={control}
                    defaultValue={editGPT?.gptType}
                    rules={{
                        required: 'Type is required.',
                    }}
                    render={({ field: { onChange, value } }) => (
                        <Switch
                            checked={value === 1 || !isSwitchEnabled ? true : false}
                            onChange={() => {
                                if (isSwitchEnabled) {
                                    // If isSwitchEnabled is true (Pre-Existing GPT), toggle between Assistant GPT and Semantic Kernel GPT
                                    onChange(value === 1 ? 0 : 1);
                                    setValue('tools', []);
                                    setValue('assistantFiles', []);
                                } else {
                                    // If isSwitchEnabled is false (New GPT), always set to Assistant GPT
                                    onChange(1);
                                }
                            }}
                            label={
                                <Text className={classes.switchText}>
                                    {value === 1 || !isSwitchEnabled ? 'Assistant GPT' : 'Semantic Kernel GPT'}
                                </Text>
                            }
                            disabled={true}
                        />
                    )}
                />
            </Field>

            <Field
                label={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Text className={classes.fieldName}>Is the GPT private?</Text>
                        <Tooltip
                            content="Indicate whether this GPT is private. A private GPT is accessible only to the GPT Owner or users with read/write permissions to the GPT (granted by the GPT owner)."
                            relationship="label"
                            positioning="after"
                        >
                            <Info24Regular
                                className={classes.iconStyle}
                                onClick={(e) => {
                                    e.preventDefault();
                                }}
                            />
                        </Tooltip>
                    </div>
                }
                validationState={errors.isPrivate ? 'error' : 'none'}
                validationMessage={errors.isPrivate ? errors.isPrivate.message : null}
            >
                <Controller
                    name="isPrivate"
                    control={control}
                    defaultValue={true}
                    render={({ }) => (
                        <Switch
                            checked={isPrivate}
                            onChange={() => setIsPrivate(!isPrivate)}
                            label={<Text className={classes.switchText}>{isPrivate ? 'Yes' : 'No'}</Text>}
                        />
                    )}
                />
            </Field>

            <FormTextField
                control={control}
                name="name"
                label={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span>Name</span>
                        <Tooltip
                            content="Provide a name for your specialized GPT. This will be used to identify it in your list of GPTs."
                            relationship="label"
                            positioning="after"
                        >
                            <Info24Regular
                                className={classes.iconStyle}
                                onClick={(e) => {
                                    e.preventDefault();
                                }}
                            />
                        </Tooltip>
                    </div>
                }
                rules={{ required: 'Name is required.' }}
                defaultValue={editGPT?.name}
                errors={errors.name}
                className={classes.fieldName}
                placeholder="Add GPT name"
                maxChars={36}
            />

            <FormTextField
                control={control}
                name="description"
                label={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span>Description</span>
                        <Tooltip
                            content="Provide a brief description of what this GPT does or its purpose."
                            relationship="label"
                            positioning="after"
                        >
                            <Info24Regular
                                className={classes.iconStyle}
                                onClick={(e) => {
                                    e.preventDefault();
                                }}
                            />
                        </Tooltip>
                    </div>
                }
                rules={{ required: 'Description is required.' }}
                defaultValue={editGPT?.description}
                errors={errors.description}
                className={classes.fieldName}
                placeholder="Add GPT description"
                maxChars={512}
            />

            {gptType == 1 && (
                <Field
                    label={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Text className={classes.fieldName}>Model:</Text>
                            <Tooltip
                                content="Select the model that powers your GPT. The model determines the capabilities and performance of the GPT."
                                relationship="label"
                                positioning="after"
                            >
                                <Info24Regular
                                    className={classes.iconStyle}
                                    onClick={(e) => {
                                        e.preventDefault();
                                    }}
                                />
                            </Tooltip>
                        </div>
                    }
                    validationState={errors.tools ? 'error' : 'none'}
                    validationMessage={errors.tools ? errors.tools.message : null}
                >
                    <Controller
                        name="model"
                        control={control}
                        defaultValue={editGPT?.model}
                        render={({ field: { value, onChange } }) => (
                            <Dropdown
                                placeholder="Select Model"
                                style={{ borderRadius: 0, border: `2px solid ${customColors.headerLightGray}` }}
                                selectedOptions={value ? [value] : []}
                                value={
                                    modelOptions.find((option: { key: string }) => option.key === value)?.header ||
                                    modelOptions[0].header
                                }
                                onOptionSelect={(_event, data) => {
                                    if (data.selectedOptions) {
                                        onChange(data.selectedOptions[0]);
                                    }
                                }}
                            >
                                {modelOptions.map(
                                    (option: {
                                        key: React.Key | null | undefined;
                                        header:
                                        | string
                                        | number
                                        | boolean
                                        | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                                        | Iterable<React.ReactNode>
                                        | null
                                        | undefined;
                                    }) => (
                                        <Option
                                            key={
                                                option.key !== null && option.key !== undefined
                                                    ? String(option.key)
                                                    : ''
                                            }
                                            text={String(option.header)}
                                            value={
                                                option.key !== null && option.key !== undefined
                                                    ? String(option.key)
                                                    : undefined
                                            }
                                        >
                                            {option.header}
                                        </Option>
                                    ),
                                )}
                            </Dropdown>
                        )}
                    />
                </Field>
            )}

            <FormTextField
                control={control}
                name="persona"
                label={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span>Persona:</span>
                        <Tooltip
                            content="Specify the persona details that define how this GPT behaves or interacts. Personas influence the tone and style of responses."
                            relationship="label"
                            positioning="after"
                        >
                            <Info24Regular
                                className={classes.iconStyle}
                                onClick={(e) => {
                                    e.preventDefault();
                                }}
                            />
                        </Tooltip>
                    </div>
                }
                rules={{ required: 'Persona is required.' }}
                defaultValue={editGPT?.persona}
                errors={errors.persona}
                multiline={true}
                className={classes.fieldName}
                placeholder="Add persona information"
                maxChars={256000}
            />

            {gptType == 0 && (
                <Field
                    label={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Text className={classes.fieldName}>Can user modify the persona?</Text>
                            <Tooltip
                                content="Toggle this option to allow or disallow users to modify the persona details."
                                relationship="label"
                                positioning="after"
                            >
                                <Info24Regular
                                    className={classes.iconStyle}
                                    onClick={(e) => {
                                        e.preventDefault();
                                    }}
                                />
                            </Tooltip>
                        </div>
                    }
                    validationState={errors.userModifyPersona ? 'error' : 'none'}
                    validationMessage={errors.userModifyPersona ? errors.userModifyPersona.message : null}
                >
                    <Controller
                        name="userModifyPersona"
                        control={control}
                        defaultValue={false}
                        render={({ }) => (
                            <Switch
                                checked={isModified}
                                onChange={() => setIsModified(!isModified)}
                                label={<Text className={classes.switchText}>{isModified ? 'Yes' : 'No'}</Text>}
                            />
                        )}
                    />
                </Field>
            )}

            {gptType == 1 && (
                <Field
                    label={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Text className={classes.fieldName}>Assistant Tools</Text>
                            <Tooltip
                                content={
                                    <div style={{ whiteSpace: 'normal', textAlign: 'left' }}>
                                        <ul style={{ margin: '0.5em 0 0.5em 1em', padding: 0 }}>
                                            <li>
                                                <strong>Code Interpreter:</strong> This tool allows the GPT to execute
                                                code snippets, interpret and analyze code, and provide responses based
                                                on the code execution results.
                                            </li>
                                            <li>
                                                <strong>File Search:</strong> This tool enables the GPT to search
                                                through uploaded files, retrieve relevant information, and use that
                                                information to generate responses.
                                            </li>
                                        </ul>
                                    </div>
                                }
                                relationship="label"
                                positioning="after"
                            >
                                <Info24Regular
                                    className={classes.iconStyle}
                                    onClick={(e) => {
                                        e.preventDefault();
                                    }}
                                />
                            </Tooltip>
                        </div>
                    }
                    validationState={errors.tools ? 'error' : 'none'}
                    validationMessage={errors.tools ? errors.tools.message : null}
                >
                    <Controller
                        name="tools"
                        control={control}
                        defaultValue={editGPT?.tools}
                        render={({ field: { value, onChange } }) => (
                            <Dropdown
                                placeholder="Select Assistant tools"
                                multiselect={true}
                                style={{ borderRadius: 0, border: `2px solid ${customColors.headerLightGray}` }}
                                value={value?.map((tool) => AssistantTools[Number(tool)]).join(', ')}
                                selectedOptions={value?.map((tool) => String(tool))}
                                onOptionSelect={(_event, data) => {
                                    const options = data.selectedOptions.map((option) => Number(option));
                                    onChange(options);
                                    if (!options.includes(AssistantTools['File Search'])) setAssistantFiles([]);
                                }}
                            >
                                {Object.keys(AssistantTools)
                                    .filter((key) => isNaN(Number(key)))
                                    .map((option) => (
                                        <Option
                                            key={option}
                                            text={option}
                                            value={String(AssistantTools[option as keyof typeof AssistantTools])}
                                        >
                                            {option}
                                        </Option>
                                    ))}
                            </Dropdown>
                        )}
                    />
                </Field>
            )}
            {gptType == 1 &&
                (assistantTools?.includes(AssistantTools['File Search']) ||
                    assistantTools?.includes(AssistantTools['Code Interpreter'])) && (
                    <Field
                        label={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Text className={classes.fieldName}>Assistant Files</Text>
                                <Tooltip
                                    content={{
                                        children: (
                                            <>
                                                <div className={classes.assistantToolTipDiv}>
                                                    <strong
                                                        style={{
                                                            fontSize: '18px',
                                                            fontWeight: 'bold',
                                                            display: 'block',
                                                            marginBottom: '1em',
                                                            color: '#333',
                                                        }}
                                                    >
                                                        {`The current file upload limit is ${parseInt(process.env.REACT_APP_MAX_FILE_COUNT_UPLOAD_ASSISTANT_LEVEL!)} files, with a maximum upload size of ${parseInt(process.env.REACT_APP_MAX_FILE_SIZE_UPLOAD_ASSISTANT_LEVEL!)} MB per file.`}
                                                    </strong>
                                                    <ul
                                                        style={{
                                                            margin: '0.5em 0 0.5em 1em',
                                                            padding: 0,
                                                            listStyleType: 'none',
                                                        }}
                                                    >
                                                        <div style={{ marginBottom: '1em' }}>
                                                            <strong style={{ fontSize: '16px', color: '#333' }}>
                                                                Code Interpreter Supported Files
                                                            </strong>
                                                            <ul
                                                                style={{
                                                                    margin: '0.5em 0 0.5em 1em',
                                                                    padding: 0,
                                                                    listStyleType: 'disc',
                                                                }}
                                                            >
                                                                <li>.c - text/x-c</li>
                                                                <li>.cs - text/x-csharp</li>
                                                                <li>.cpp - text/x-c++</li>
                                                                <li>.csv - text/csv</li>
                                                                <li>.doc - application/msword</li>
                                                                <li>
                                                                    .docx -
                                                                    application/vnd.openxmlformats-officedocument.wordprocessingml.document
                                                                </li>
                                                                <li>.html - text/html</li>
                                                                <li>.java - text/x-java</li>
                                                                <li>.json - application/json</li>
                                                                <li>.md - text/markdown</li>
                                                                <li>.pdf - application/pdf</li>
                                                                <li>.php - text/x-php</li>
                                                                <li>
                                                                    .pptx -
                                                                    application/vnd.openxmlformats-officedocument.presentationml.presentation
                                                                </li>
                                                                <li>.py - text/x-python</li>
                                                                <li>.py - text/x-script.python</li>
                                                                <li>.rb - text/x-ruby</li>
                                                                <li>.tex - text/x-tex</li>
                                                                <li>.txt - text/plain</li>
                                                                <li>.css - text/css</li>
                                                                <li>.js - text/javascript</li>
                                                                <li>.sh - application/x-sh</li>
                                                                <li>.ts - application/typescript</li>
                                                                <li>.csv - application/csv</li>
                                                                <li>.jpeg - image/jpeg</li>
                                                                <li>.jpg - image/jpeg</li>
                                                                <li>.gif - image/gif</li>
                                                                <li>.pkl - application/octet-stream</li>
                                                                <li>.png - image/png</li>
                                                                <li>.tar - application/x-tar</li>
                                                                <li>
                                                                    .xlsx -
                                                                    application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
                                                                </li>
                                                                <li>.xml - application/xml or "text/xml"</li>
                                                                <li>.zip - application/zip</li>
                                                            </ul>
                                                        </div>
                                                        <div>
                                                            <strong style={{ fontSize: '16px', color: '#333' }}>
                                                                File Search Supported Files
                                                            </strong>
                                                            <br />
                                                            <span style={{ color: '#d9534f' }}>
                                                                For text/ MIME types, the encoding must be one of utf-8,
                                                                utf-16, or ascii.
                                                            </span>
                                                            <ul
                                                                style={{
                                                                    margin: '0.5em 0 0.5em 1em',
                                                                    padding: 0,
                                                                    listStyleType: 'disc',
                                                                }}
                                                            >
                                                                <li>.c - text/x-c</li>
                                                                <li>.cpp - text/x-c++</li>
                                                                <li>.cs - text/x-csharp</li>
                                                                <li>.css - text/css</li>
                                                                <li>.doc - application/msword</li>
                                                                <li>
                                                                    .docx -
                                                                    application/vnd.openxmlformats-officedocument.wordprocessingml.document
                                                                </li>
                                                                <li>.go - text/x-golang</li>
                                                                <li>.html - text/html</li>
                                                                <li>.java - text/x-java</li>
                                                                <li>.js - text/javascript</li>
                                                                <li>.json - application/json</li>
                                                                <li>.md - text/markdown</li>
                                                                <li>.pdf - application/pdf</li>
                                                                <li>.php - text/x-php</li>
                                                                <li>
                                                                    .pptx -
                                                                    application/vnd.openxmlformats-officedocument.presentationml.presentation
                                                                </li>
                                                                <li>.py - text/x-python</li>
                                                                <li>.py - text/x-script.python</li>
                                                                <li>.rb - text/x-ruby</li>
                                                                <li>.sh - application/x-sh</li>
                                                                <li>.tex - text/x-tex</li>
                                                                <li>.ts - application/typescript</li>
                                                                <li>.txt - text/plain</li>
                                                            </ul>
                                                        </div>
                                                    </ul>
                                                </div>
                                            </>
                                        ),
                                        className: classes.assistantToolTip,
                                    }}
                                    relationship="label"
                                    positioning="after"
                                >
                                    <Info24Regular
                                        className={classes.iconStyle}
                                        onClick={(e) => {
                                            e.preventDefault();
                                        }}
                                    />
                                </Tooltip>
                            </div>
                        }
                        validationState={assistantFiles.length == 0 && existingFiles.length == 0 ? 'error' : 'none'}
                        validationMessage={
                            (assistantFiles.length != 0 && existingFiles.length != 0) ||
                                (assistantTools?.includes(AssistantTools['Code Interpreter']) &&
                                    !assistantTools?.includes(AssistantTools['File Search']))
                                ? null
                                : 'At least 1 file is required.'
                        }
                    >
                        <div className={classes.functional}>
                            <input
                                type="file"
                                ref={documentFileRef}
                                style={{ display: 'none' }}
                                accept={getAcceptedFiles()}
                                multiple={true}
                                onChange={() => {
                                    if (documentFileRef.current && documentFileRef.current.files) {
                                        const fileList = Array.from(documentFileRef.current.files);
                                        const newFiles: File[] = [];
                                        const maxFileSizeMB = parseInt(
                                            process.env.REACT_APP_MAX_FILE_SIZE_UPLOAD_ASSISTANT_LEVEL!,
                                        );
                                        const maxFileCount = parseInt(
                                            process.env.REACT_APP_MAX_FILE_COUNT_UPLOAD_ASSISTANT_LEVEL!,
                                        );
                                        const maxFileSizeBytes = maxFileSizeMB * 1024 * 1024;
                                        let currentTotalFiles = totalFilesAfterUpload;

                                        /* Sanitize file content */
                                        Promise.all(
                                            fileList.map((file) =>
                                                sanitizeAndProcessFile(
                                                    file,
                                                    currentTotalFiles,
                                                    maxFileCount,
                                                    maxFileSizeBytes,
                                                    maxFileSizeMB,
                                                    classes,
                                                ),
                                            ),
                                        ).then((processedFiles) => {
                                            processedFiles.forEach((file) => {
                                                if (file) {
                                                    newFiles.push(file);
                                                    selectedFileImports.current.add(file.name);
                                                    currentTotalFiles++;
                                                }
                                            });

                                            if (currentTotalFiles > maxFileCount) {
                                                toast.error(`You can only upload a maximum of ${maxFileCount} files.`, {
                                                    className: classes.toastMessage,
                                                });
                                            }

                                            setTotalFilesAfterUpload(currentTotalFiles);
                                            setAssistantFiles([...assistantFiles, ...newFiles]);
                                        });
                                    }
                                }}
                            />
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <Button
                                    shape="square"
                                    icon={
                                        <ArrowUploadRegular
                                            style={{ fontSize: '30px', color: customColors.headerLightGray }}
                                        />
                                    }
                                    onClick={() => documentFileRef.current?.click()}
                                >
                                    Upload files ({totalFilesAfterUpload})
                                </Button>
                                <Button
                                    shape="square"
                                    icon={
                                        <DeleteFilled
                                            style={{ fontSize: '30px', color: customColors.headerLightGray }}
                                        />
                                    }
                                    onClick={() => {
                                        setAssistantFiles(
                                            assistantFiles.filter((file) => !selectedFileDeletes.includes(file.name)),
                                        );
                                        setDeletedExistingFiles(
                                            existingFiles.filter((file) => selectedFileDeletes.includes(file.fileName)),
                                        );
                                        setExistingFiles(
                                            existingFiles.filter(
                                                (file) => !selectedFileDeletes.includes(file.fileName),
                                            ),
                                        );
                                        selectedFileDeletes.forEach((file) => {
                                            selectedFileImports.current.delete(String(file));
                                        });
                                        setTotalFilesAfterUpload(totalFilesAfterUpload - selectedFileDeletes.length);
                                        setSelectedFileDeletes([]);
                                        if (documentFileRef.current?.value) {
                                            documentFileRef.current.value = '';
                                        }
                                    }}
                                >
                                    Delete selected files
                                </Button>
                            </div>
                            <DataGrid
                                items={displayedFiles}
                                columns={fileTableColumns}
                                selectionMode="multiselect"
                                selectedItems={selectedFileDeletes}
                                onSelectionChange={(_e, data) => {
                                    setSelectedFileDeletes(Array.from(data.selectedItems));
                                }}
                                getRowId={(item) => item.file}
                                focusMode="composite"
                                style={{ minWidth: '550px' }}
                            >
                                <DataGridHeader>
                                    <DataGridRow
                                        selectionCell={{
                                            checkboxIndicator: { 'aria-label': 'Select all files' },
                                        }}
                                    >
                                        {({ renderHeaderCell }) => (
                                            <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                                        )}
                                    </DataGridRow>
                                </DataGridHeader>
                                <DataGridBody<FileTableItem>>
                                    {({ item, rowId }) => (
                                        <DataGridRow<FileTableItem>
                                            key={rowId}
                                        // selectionCell={{
                                        //     checkboxIndicator: { "aria-label": "Select file" }}}
                                        >
                                            {({ renderCell }) => <DataGridCell>{renderCell(item)}</DataGridCell>}
                                        </DataGridRow>
                                    )}
                                </DataGridBody>
                            </DataGrid>
                        </div>
                    </Field>
                )}

            {assistantTools?.includes(AssistantTools['File Search']) && userRole === '0' && (
                <>
                    <Field
                        label={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Text className={classes.fieldName}>File Search Configuration</Text>
                                <Tooltip
                                    content="Indicate whether the File Search should use specific configurations. Leave the configs at 0, if default is preferred or simply turn off this toggle."
                                    relationship="label"
                                    positioning="after"
                                >
                                    <Info24Regular
                                        className={classes.iconStyle}
                                        onClick={(e) => {
                                            e.preventDefault();
                                        }}
                                    />
                                </Tooltip>
                            </div>
                        }
                        validationState={errors.isPrivate ? 'error' : 'none'}
                        validationMessage={errors.isPrivate ? errors.isPrivate.message : null}
                    >
                        <Controller
                            name="fileSearchConfig.isEnabled"
                            control={control}
                            defaultValue={editGPT?.fileSearchConfig?.isEnabled ? true : false}
                            render={({ }) => (
                                <Switch
                                    checked={isFileSeachConfigEnabled}
                                    onChange={() => setIsFileSearchConfigEnabled(!isFileSeachConfigEnabled)}
                                    label={
                                        <Text className={classes.switchText}>
                                            {isFileSeachConfigEnabled ? 'Enabled' : 'Disabled'}
                                        </Text>
                                    }
                                />
                            )}
                        />
                    </Field>
                    {isFileSeachConfigEnabled && (
                        <Field>
                            <Controller
                                name="fileSearchConfig.maxChunkSizeTokens"
                                control={control}
                                defaultValue={editGPT?.fileSearchConfig?.maxChunkSizeTokens ?? maxChunkSizeTokens}
                                render={({ field }) => {
                                    let { value, onChange, ...restField } = field;
                                    const handleChange = (event: any) => {
                                        let value = event.target.value;
                                        value = Math.min(Math.max(100, value), 4096);
                                        setMaxChunkSizeTokens(value);
                                        onChange({ target: { value: value } });
                                    };
                                    return (
                                        <Field
                                            label={
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        padding: '10px 0 0 0',
                                                    }}
                                                >
                                                    <Text className={classes.fieldName}>Max Chunk Size Tokens</Text>
                                                    <Tooltip
                                                        content="The maximum chunk size tokens is an optional parameter that sets a limit on the number of tokens in a chunk. The default is 800."
                                                        relationship="label"
                                                        positioning="after"
                                                    >
                                                        <Info24Regular
                                                            className={classes.iconStyle}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </div>
                                            }
                                            validationState={
                                                errors.fileSearchConfig?.maxChunkSizeTokens ? 'error' : 'none'
                                            }
                                            validationMessage={
                                                errors.fileSearchConfig?.maxChunkSizeTokens
                                                    ? errors.fileSearchConfig.maxChunkSizeTokens.message
                                                    : null
                                            }
                                        >
                                            <Input
                                                {...restField}
                                                onChange={handleChange}
                                                type="number"
                                                value={value !== undefined ? String(value) : ''}
                                                step="100"
                                            />
                                        </Field>
                                    );
                                }}
                            />

                            <Controller
                                name="fileSearchConfig.chunkOverlapTokens"
                                control={control}
                                defaultValue={editGPT?.fileSearchConfig?.chunkOverlapTokens ?? maxChunkOverlapTokens}
                                render={({ field }) => {
                                    let { value, onChange, ...restField } = field;
                                    const handleChange = (event: any) => {
                                        let value = event.target.value;
                                        value = Math.min(
                                            Math.max(100, value),
                                            maxChunkSizeTokens ? maxChunkSizeTokens / 2 : 400,
                                        );
                                        setMaxChunkOverlapTokens(value);
                                        onChange({ target: { value: value } });
                                    };
                                    return (
                                        <Field
                                            label={
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        padding: '10px 0 0 0',
                                                    }}
                                                >
                                                    <Text className={classes.fieldName}>Chunk Overlap Tokens</Text>
                                                    <Tooltip
                                                        content="The chunk overlap tokens is an optional parameter that sets a limit on the number of tokens that overlap between chunks. The default is 400."
                                                        relationship="label"
                                                        positioning="after"
                                                    >
                                                        <Info24Regular
                                                            className={classes.iconStyle}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </div>
                                            }
                                            validationState={
                                                errors.fileSearchConfig?.chunkOverlapTokens ? 'error' : 'none'
                                            }
                                            validationMessage={
                                                errors.fileSearchConfig?.chunkOverlapTokens
                                                    ? errors.fileSearchConfig.chunkOverlapTokens.message
                                                    : null
                                            }
                                        >
                                            <Input
                                                {...restField}
                                                onChange={handleChange}
                                                type="number"
                                                value={value !== undefined ? String(value) : ''}
                                                step="100"
                                            />
                                        </Field>
                                    );
                                }}
                            />

                            <Controller
                                name="fileSearchConfig.maxNumResults"
                                defaultValue={editGPT?.fileSearchConfig?.maxNumResults ?? maxNumResults}
                                control={control}
                                render={({ field }) => {
                                    let { value, onChange, ...restField } = field;
                                    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
                                        let value = parseFloat(event.target.value);
                                        value = Math.max(5, Math.min(50, value));
                                        setMaxNumResults(value);
                                        onChange({ target: { value: value.toString() } });
                                    };
                                    return (
                                        <Field
                                            label={
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        padding: '10px 0 0 0',
                                                    }}
                                                >
                                                    <Text className={classes.fieldName}>Max Num Results</Text>
                                                    <Tooltip
                                                        content="The maximum number of results is an optional parameter that sets a limit on the number of results returned by the file search. The default is 20."
                                                        relationship="label"
                                                        positioning="after"
                                                    >
                                                        <Info24Regular
                                                            className={classes.iconStyle}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </div>
                                            }
                                            validationState={errors.fileSearchConfig?.maxNumResults ? 'error' : 'none'}
                                            validationMessage={
                                                errors.fileSearchConfig?.maxNumResults
                                                    ? errors.fileSearchConfig.maxNumResults.message
                                                    : null
                                            }
                                        >
                                            <Input
                                                {...restField}
                                                value={String(value)}
                                                type="number"
                                                onChange={handleChange}
                                                defaultValue={'20'}
                                                step="5"
                                            />
                                        </Field>
                                    );
                                }}
                            />
                        </Field>
                    )}
                </>
            )}

            {gptType == 1 &&
                (assistantTools?.includes(AssistantTools['File Search']) ||
                    assistantTools?.includes(AssistantTools['Code Interpreter'])) && (
                    <Field
                        label={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Text className={classes.fieldName}>Is Chat Document Upload Enabled?</Text>
                                <Tooltip
                                    content="Enabling chat specific file uploads that dont apply to the overall GPT"
                                    relationship="label"
                                    positioning="after"
                                >
                                    <Info24Regular
                                        className={classes.iconStyle}
                                        onClick={(e) => {
                                            e.preventDefault();
                                        }}
                                    />
                                </Tooltip>
                            </div>
                        }
                        validationState={errors.isDocumentUploadEnabled ? 'error' : 'none'}
                        validationMessage={
                            errors.isDocumentUploadEnabled ? errors.isDocumentUploadEnabled.message : null
                        }
                    >
                        <Controller
                            name="isDocumentUploadEnabled"
                            control={control}
                            defaultValue={false}
                            render={({ }) => (
                                <Switch
                                    checked={isDocumentUploadEnabled}
                                    onChange={() => setIsDocumentUploadEnabled(!isDocumentUploadEnabled)}
                                    label={
                                        <Text className={classes.switchText}>
                                            {isDocumentUploadEnabled ? 'Yes' : 'No'}
                                        </Text>
                                    }
                                />
                            )}
                        />
                    </Field>
                )}

            {/* <FormTextField
                control={control}
                name="systemMetaData"
                label="SystemMetaData:"
                defaultValue={editGPT?.systemMetaData}
                errors={errors.systemMetaData}
            /> */}
            <Divider className={classes.divider}>GPT Identifiers</Divider>

            <Field
                label={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Text className={classes.fieldName}>Upload Card Image</Text>
                        <Tooltip
                            content="Upload the image you want to display as a thumbnail for this GPT card."
                            relationship="label"
                            positioning="after"
                        >
                            <Info24Regular
                                className={classes.iconStyle}
                                onClick={(e) => {
                                    e.preventDefault();
                                }}
                            />
                        </Tooltip>
                    </div>
                }
                validationState={selectedFileName == null ? 'error' : 'none'}
                validationMessage={selectedFileName ? null : 'Card Image Upload is required.'}
            >
                <div>
                    {/* Hidden file input */}
                    <input
                        type="file"
                        ref={fileInputRef}
                        name="gptIconFileName"
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                    />

                    {/* Fluent UI Button */}
                    <Button
                        icon={<ArrowUploadRegular style={{ fontSize: '30px', color: customColors.headerLightGray }} />}
                        onClick={() => fileInputRef.current?.click()}
                    >
                        Upload Image
                    </Button>

                    {/* Display selected file name */}
                    {selectedFile ? (
                        <span> {selectedFile.name}</span>
                    ) : (
                        <span> {selectedFileName}</span> // Name of uploaded file before the update
                    )}
                </div>
            </Field>

            <FormTextField
                control={control}
                name="cardShortDescription"
                label={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span>Card Short Description:</span>
                        <Tooltip
                            content="Provide a short description that will be displayed on the GPT card."
                            relationship="label"
                            positioning="after"
                        >
                            <Info24Regular
                                className={classes.iconStyle}
                                onClick={(e) => {
                                    e.preventDefault();
                                }}
                            />
                        </Tooltip>
                    </div>
                }
                rules={{ required: 'Card Short Description is required.' }}
                defaultValue={editGPT?.cardShortDescription}
                errors={errors.cardShortDescription}
                className={classes.fieldName}
                placeholder="Add card GPT description"
            />

            <Field
                label={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Text className={classes.fieldName}>Tags</Text>
                        <Tooltip
                            content="Enter relevant tags separated by commas to categorize your GPT for easy searching and filtering."
                            relationship="label"
                            positioning="after"
                        >
                            <Info24Regular
                                className={classes.iconStyle}
                                onClick={(e) => {
                                    e.preventDefault();
                                }}
                            />
                        </Tooltip>
                    </div>
                }
                validationState={errors.tag ? 'error' : 'none'}
                validationMessage={errors.tag ? errors.tag.message : null}
            >
                <Controller
                    name="tag"
                    control={control}
                    defaultValue={editGPT?.tag}
                    render={({ field }) => (
                        <Input
                            {...field}
                            style={{ borderRadius: 0, border: `2px solid ${customColors.headerLightGray}` }}
                            placeholder="Example: Tag 1, Tag 2, Tag 3"
                        />
                    )}
                />
            </Field>

            <Divider className={classes.divider}>GPT Overview Information</Divider>

            <Controller
                name="ownershipMode"
                control={control}
                defaultValue={ownershipMode}
                rules={{
                    required: 'Ownership mode is required.',
                }}
                render={({ field: { onChange, value } }) => (
                    <Switch
                        checked={value === 'owners'}
                        onChange={() => {
                            const newMode = value === 'owners' ? 'support' : 'owners';
                            onChange(newMode);
                            if (newMode === 'owners') {
                                setValue('gptSupportAddress', '');
                            } else {
                                setOwners(['']);
                            }
                        }}
                        label={
                            <Text className={classes.switchText}>
                                {value === 'owners'
                                    ? 'Multiple GPT Owners'
                                    : 'Single GPT Support Address'}
                            </Text>
                        }
                    />
                )}
            />

            {watch('ownershipMode') === 'support' && (
                <FormTextField
                    control={control}
                    name="gptSupportAddress"
                    label={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span>GPT Support Address</span>
                            <Tooltip
                                content="GPT Support Address"
                                relationship="label"
                                positioning="after"
                            >
                                <Info24Regular
                                    className={classes.iconStyle}
                                    onClick={(e) => e.preventDefault()}
                                />
                            </Tooltip>
                        </div>
                    }
                    rules={{
                        required: 'Email Address is required if you choose GPT Support mode.',
                        pattern: {
                            value: /^[a-zA-Z0-9._%+-]+@(roberthalf|protiviti)\.com$/,
                            message: 'Must be a roberthalf.com or protiviti.com address',
                        },
                    }}
                    defaultValue=""
                    className={classes.fieldName}
                    placeholder="Example: user@roberthalf.com"
                    errors={errors.gptSupportAddress}
                />
            )}

            {watch('ownershipMode') === 'owners' && (
                <Field
                    label={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Text className={classes.fieldName}>GPT Owners</Text>
                            <Tooltip
                                content="Enter the email addresses of the GPT owners. Multiple owners can be added."
                                relationship="label"
                                positioning="after"
                            >
                                <Info24Regular
                                    className={classes.iconStyle}
                                    onClick={(e) => e.preventDefault()}
                                />
                            </Tooltip>
                        </div>
                    }
                >
                    {owners.map((owner, index) => (
                        <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                            <Input
                                value={owner}
                                onChange={(e) => {
                                    const updatedOwners = [...owners];
                                    updatedOwners[index] = e.target.value;
                                    setOwners(updatedOwners);
                                }}
                                placeholder="Example: user@protiviti.com"
                                style={{
                                    flex: 1,
                                    borderRadius: 0,
                                    border: `2px solid ${customColors.headerLightGray}`,
                                }}
                            />
                            <DeleteFilled
                                onClick={() => {
                                    if (owners.length > 1) {
                                        setOwners(owners.filter((_, i) => i !== index));
                                    }
                                }}
                                style={{ fontSize: '30px', color: customColors.headerLightGray, marginLeft: '8px', cursor: 'pointer' }}
                            />
                        </div>
                    ))}
                    <Button
                        type="button"
                        appearance="subtle"
                        onClick={() => setOwners([...owners, ''])}
                        icon={<AddCircleRegular />}
                        shape="square"
                        style={{ fontSize: '16px', width: 'max-content' }}
                    >
                        Add Another Owner
                    </Button>
                </Field>
            )}


            <FormTextField
                control={control}
                name="gptOwnerBlurb"
                label="Short Blurb For Users:"
                defaultValue={editGPT?.gptOwnerBlurb ?? ''}
                errors={errors.gptOwnerBlurb}
                className={classes.fieldName}
                placeholder="Example: 'I hope you enjoy XYZ GPT! Feel free to ask me any questions or provide feedback.'"
                multiline={true}
                maxChars={200}
            />
            <FormTextField
                control={control}
                name="gptKnowledgeBase"
                label={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span>Knowledge Source For GPT</span>
                        <Tooltip
                            content="Describe the knowledge source or provide a link that the GPT uses as a reference for generating responses."
                            relationship="label"
                            positioning="after"
                        >
                            <Info24Regular
                                className={classes.iconStyle}
                                onClick={(e) => {
                                    e.preventDefault();
                                }}
                            />
                        </Tooltip>
                    </div>
                }
                defaultValue={editGPT?.gptKnowledgeBase ?? ''}
                errors={errors.gptKnowledgeBase}
                className={classes.fieldName}
                placeholder="Provide a link or a description of the knowledge source for the GPT"
                multiline={true}
                maxChars={700}
            />
            <Field
                label={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Text className={classes.fieldName}>Capabilities</Text>
                        <Tooltip
                            content="List the capabilities of this GPT, separated by commas. Capabilities could include specific tasks or functions the GPT can perform."
                            relationship="label"
                            positioning="after"
                        >
                            <Info24Regular
                                className={classes.iconStyle}
                                onClick={(e) => {
                                    e.preventDefault();
                                }}
                            />
                        </Tooltip>
                    </div>
                }
                validationState={errors.gptCapabilities ? 'error' : 'none'}
                validationMessage={errors.gptCapabilities ? errors.gptCapabilities.message : null}
            >
                {capabilities?.map((capability, index) => (
                    <div key={index} style={{ display: 'flex', alignItems: 'center', justifyItems: 'center' }}>
                        <Controller
                            name="gptCapabilities"
                            defaultValue={editGPT?.gptCapabilities ?? []}
                            control={control}
                            render={({ field }) => (
                                <Input
                                    style={{
                                        width: '100%',
                                        borderRadius: 0,
                                        border: `2px solid ${customColors.headerLightGray}`,
                                        marginBottom: '8px',
                                    }}
                                    {...field}
                                    value={capability}
                                    onChange={(e) => {
                                        field.onChange(e.target.value);
                                        handleCapabilityInputChange(e.target.value, index);
                                    }}
                                    placeholder="Example: Data analysis, Email draft generation, etc."
                                />
                            )}
                        />
                        <DeleteFilled
                            type="button"
                            onClick={() => removeCapability(index)}
                            style={{
                                fontSize: '30px',
                                color: customColors.headerLightGray,
                                marginLeft: '8px',
                                marginBottom: '8px',
                            }}
                        />
                    </div>
                ))}
                <Button
                    type="button"
                    appearance="subtle"
                    onClick={addCapability}
                    icon={<AddCircleRegular />}
                    shape="square"
                    style={{ fontSize: '16px', width: 'max-content' }}
                >
                    Add Capability
                </Button>
            </Field>
            <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Text className={classes.fieldName}>Prompt Library</Text>
                    <Tooltip
                        content="Add and manage prompts that can be pre-defined instructions or questions the GPT should be able to respond to."
                        relationship="label"
                        positioning="after"
                    >
                        <Info24Regular
                            className={classes.iconStyle}
                            onClick={(e) => {
                                e.preventDefault();
                            }}
                        />
                    </Tooltip>
                </div>
                {promptLibraryFields.map((promptLibrary, promptLibraryIndex) => (
                    <div key={promptLibrary.id} style={{ marginBottom: '0px', display: 'flex', alignItems: 'center' }}>
                        <NestedItemsFieldArray
                            control={control}
                            name={`promptLibrary.${promptLibraryIndex}.smallBlock`}
                            isFaq={false}
                            field1={'name'}
                            field2={'description'}
                        />
                        <DeleteFilled
                            type="button"
                            onClick={() => removePromptLibrary(promptLibraryIndex)}
                            style={{ fontSize: '30px', color: customColors.headerLightGray, marginLeft: '8px' }}
                        />
                    </div>
                ))}

                {promptLibraryFields.length < 6 && (
                    <Button
                        type="button"
                        appearance="subtle"
                        shape="square"
                        icon={<AddCircleRegular />}
                        style={{ fontSize: '1.2em', fontWeight: 500, marginTop: '16px', minWidth: 0 }}
                        onClick={() =>
                            appendPromptLibrary({
                                smallBlock: [{ name: '', description: '' }],
                            })
                        }
                    >
                        Add Prompt
                    </Button>
                )}
            </div>

            <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Text className={classes.fieldName}>FAQs</Text>
                    <Tooltip
                        content="Add frequently asked questions (FAQs) that users might have about this GPT and provide appropriate answers."
                        relationship="label"
                        positioning="after"
                    >
                        <Info24Regular
                            className={classes.iconStyle}
                            onClick={(e) => {
                                e.preventDefault();
                            }}
                        />
                    </Tooltip>
                </div>
                {faqFields.length > 0 && (
                    <div style={{ marginBottom: '16px' }}>
                        <NestedItemsFieldArray
                            control={control}
                            name={`faQs`}
                            isFaq={true}
                            field1={'question'}
                            field2={'answer'}
                        />
                    </div>
                )}
            </div>
            <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Text className={classes.fieldName}>Related Links</Text>
                    <Tooltip content="Add links related to this GPT." relationship="label" positioning="after">
                        <Info24Regular
                            className={classes.iconStyle}
                            onClick={(e) => {
                                e.preventDefault();
                            }}
                        />
                    </Tooltip>
                </div>
                {relatedLinksFields.length > 0 && (
                    <div style={{ marginBottom: '16px' }}>
                        <NestedItemsFieldArray
                            control={control}
                            name={`relatedLinks`}
                            isFaq={true}
                            field1={'title'}
                            field2={'link'}
                        />
                    </div>
                )}
            </div>

            <FluentProvider>
                <Button
                    size="large"
                    appearance="secondary"
                    shape="square"
                    disabled={isSubmitting || disableButton}
                    style={{
                        fontSize: '1.2em',
                        padding: '10px 40px',
                        marginRight: '15px',
                        border: `2px solid ${customColors.headerLightGray}`,
                    }}
                    onClick={handleCancel}
                >
                    Cancel
                </Button>
                <Button
                    size="large"
                    appearance="secondary"
                    shape="square"
                    disabled={isSubmitting || disableButton}
                    type="submit"
                    style={{
                        fontSize: '1.2em',
                        padding: '10px 40px',
                        border: `2px solid ${customColors.protivitiOrange}`,
                    }}
                >
                    {isSubmitting ? 'Updating...' : 'Update GPT'}
                </Button>
            </FluentProvider>
            <ToastContainer autoClose={toastAppearance} />
        </form>
    );
};
