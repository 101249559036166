import { IADEmployee } from './ADEmployee';
import { IFAQ } from './FAQ';

export interface Rating {
    id: string;
    partitionID: string;
    createdDate: string;
    modifiedDate: string;
    userId: string;
    customGPTId: string;
    value: number;
    feedback: string;
}

export interface Item {
    name: string;
    description: string;
}

export interface SmallBlock {
    name: string;
    description: string;
}

export interface PromptLibrary {
    smallBlock: SmallBlock[];
}

export interface RelatedLinks {
    title: string;
    link: string;
}

export interface IGPT {
    id: string;
    partitionID: string;
    gptType: number;
    model: string;
    tools?: number[];
    assistantFiles?: IFile[];
    gptIconFile?: File | null | undefined;
    vectorStoreIds?: string[];
    createdDate?: string;
    modifiedDate?: string;
    gptEndpoint: string;
    name: string;
    description: string;
    isPrivate: boolean;
    isActive: boolean;
    isRegistered: boolean;
    isDeleted?: boolean;
    categoryTagStrings: string[];
    persona: string;
    userModifyPersona: boolean;
    //systemMetaData: string;
    cardImageURL?: string;
    gptIconFileName: string;
    cardShortDescription: string;
    tag: string;
    ratings: Rating[] | null | undefined;
    promptLibrary: PromptLibrary[];
    totalRating: number | null | undefined;
    isDocumentUploadEnabled?: boolean;
    faQs?: IFAQ[] | undefined;
    gptKnowledgeBase?: string;
    gptOwnerInfo?: IADEmployee[];
    gptOwnerBlurb?: string;
    gptCapabilities?: string[] | null | undefined;
    fileSearchConfig?: IFileSearchConfig;
    threadRunConfig?: IThreadRunConfig;
    relatedLinks: RelatedLinks[] | undefined;
    gptSupportAddress?: string;
    ownershipMode?: 'owners' | 'support';
}

export interface IFile {
    id: string;
    assistantId: string;
    createdAt?: string;
    purpose?: string;
    fileName: string;
    bytes: number;
}

export interface IFileSearchConfig {
    isEnabled: boolean;
    chunkOverlapTokens?: number;
    maxChunkSizeTokens?: number;
    maxNumResults?: number;
}

export interface IThreadRunConfig {
    temperature?: number;
    maxPromptTokens?: number;
    maxCompletionTokens?: number;
}

export enum AssistantTools {
    'Code Interpreter' = 0,
    'File Search' = 1,
    //'Function Calling' = 2,
}
