import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Tooltip,
    makeStyles,
    shorthands,
} from '@fluentui/react-components';
import { Info16Regular } from '@fluentui/react-icons';
import React from 'react';
import { IChatMessage } from '../../../libs/models/ChatMessage';
import { customColors, customFonts, ScrollBarStyles } from '../../../styles';
import { RootState } from '../../../redux/app/store';
import { useAppSelector } from '../../../redux/app/hooks';

const useClasses = makeStyles({
    infoButton: {
        ...shorthands.padding(0),
        ...shorthands.margin(0),
        minWidth: 'auto',
        marginLeft: 'auto', // align to right
    },
    title: {
        fontFamily: customFonts.TitleFont,
        fontWeight: '600',
        fontSize: '28px',
    },
    text: {
        width: '100%',
        overflowWrap: 'break-word',
        fontFamily: customFonts.Lato,
    },
    surface: {
        ...shorthands.borderRadius('0'),
        height: 'fit-content',
        ...ScrollBarStyles,
    },
    content: {
        fontFamily: customFonts.TitleFont,
        fontWeight: '500',
        fontSize: '20px',
    },
    paragraphs: {
        fontFamily: customFonts.Lato,
        fontSize: '15px',
    },
    innerContent: {
        fontFamily: customFonts.Lato,
        marginTop: '15px',
    },
    unformattedTitle: {
        fontFamily: customFonts.Lato,
        fontSize: '15px',
        fontWeight: '700',
    },
    item: {
        fontFamily: customFonts.Lato,
        fontSize: '12px',
    },
    tablist: {},
    tab: {
        '& > span': {
            fontFamily: customFonts.TitleFont,
            fontSize: '17px',
            fontWeight: '500',
        },
        '&::after': {
            backgroundColor: customColors.protivitiOrange,
            height: '5px',
            ...shorthands.borderRadius('0'),
        },
        '&::before': {
            backgroundColor: customColors.protivitiOrange,
            height: '5px',
            ...shorthands.borderRadius('0'),
        },
        '&::selection': {
            backgroundColor: customColors.protivitiOrange,
            height: '5px',
            ...shorthands.borderRadius('0'),
        },
    },
    footer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        minWidth: '175px',
        fontFamily: customFonts.Lato,
        fontSize: '12px',
    },
    button: {
        backgroundColor: customColors.white,
        fontFamily: customFonts.Lato,
        fontSize: '12px',
        ...shorthands.borderRadius('0'),
        ...shorthands.border('1.5px', 'solid', customColors.protivitiOrange),
        '&:hover': {
            backgroundColor: customColors.summarizeButtonDarker,
            color: customColors.white,
        },
    },
});

interface IPromptDialogProps {
    message: IChatMessage;
}

export const PromptDialog: React.FC<IPromptDialogProps> = ({ message }) => {
    const classes = useClasses();
    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);

    let prompt: JSX.Element | JSX.Element[];
    prompt = (
        <div>
            {conversations[selectedId].customGPTId === process.env.REACT_APP_DEFAULT_PROGPT && (
                <>
                    <p>Message Id: {message.id}</p>
                    <p>Model Id: {message.modelId}</p>
                </>
            )}
            {Object.entries(message?.tokenUsage ?? {}).map(([key, value]) => (
                <p key={key}>
                    {(key === 'promptTokens' ? 'Input/' : key === 'completionTokens' ? 'Output/' : '') +
                        key
                            .replace(/([A-Z])/g, ' $1')
                            .replace(/^./, (str) => str.toUpperCase())
                            .trim()}
                    : <span style={{ textDecoration: 'underline' }}>{value}</span>
                </p>
            ))}
        </div>
    );

    return (
        <Dialog>
            <DialogTrigger disableButtonEnhancement>
                <Tooltip content={'Show prompt'} relationship="label">
                    <Button className={classes.infoButton} icon={<Info16Regular />} appearance="transparent" />
                </Tooltip>
            </DialogTrigger>
            <DialogSurface className={classes.surface}>
                {conversations[selectedId].customGPTId !== process.env.REACT_APP_DEFAULT_PROGPT ? (
                    <DialogBody>
                        <DialogTitle className={classes.title}>Token Usage</DialogTitle>
                        <DialogContent className={classes.content}>
                            <div
                                className={
                                    message.prompt && typeof prompt !== 'string' ? classes.innerContent : undefined
                                }
                            >
                                {prompt}
                            </div>
                        </DialogContent>
                        <DialogActions position="start" className={classes.footer}>
                            <DialogTrigger disableButtonEnhancement>
                                <Button className={classes.button} appearance="secondary">
                                    Close
                                </Button>
                            </DialogTrigger>
                        </DialogActions>
                    </DialogBody>
                ) : (
                    <DialogBody>
                        <DialogTitle className={classes.title}>Message Info</DialogTitle>
                        <DialogContent className={classes.content}>
                            <div>
                                {prompt}
                            </div>
                        </DialogContent>
                        <DialogActions position="start" className={classes.footer}>
                            <DialogTrigger disableButtonEnhancement>
                                <Button className={classes.button} appearance="secondary">
                                    Close
                                </Button>
                            </DialogTrigger>
                        </DialogActions>
                    </DialogBody>
                )}
            </DialogSurface>
        </Dialog>
    );
};
